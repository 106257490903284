import React from "react";
import Package from "../../components/Package";
const Packages = (props) => {
  const { data } = props;

  return (
    <div className="max-w-5xl mx-auto py-10 md:py-10 px-5 " id="packages">
      <div className="w-full text-5xl md:text-6xl font-bold">PACKAGES</div>
      {data?.map((packageDetails, index) => {
        return (
          <div key={index}>
            <Package data={packageDetails} />
          </div>
        );
      })}
    </div>
  );
};

export default Packages;
